import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import { BaseContainer, Content } from "./styles";

const BaseLayout = (props, children) => {
  return (
    <BaseContainer>
      <Sidebar />
      <Content>
        <Outlet />
      </Content>
    </BaseContainer>
  );
};

export default BaseLayout;
