import { Link } from "react-router-dom";
import styled from "styled-components";
import myImage from "../../assets/bglogin.jpg";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const LoginContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PhotoContainer = styled.div`
  height: 100vh;
  background-image: url(${myImage});
  background-position: center;
  background-size: cover;
  width: 50%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Disclaimer = styled.p`
  font-size: 13px;
  color: white;
`;

export const DisclaimerLink = styled(Link)`
  font-size: 13px;
  font-weight: 600;
  color: #1d0cff;
  text-decoration: none;
`;
