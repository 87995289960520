import { useEffect, useState } from "react";
import { Danger, Highlight } from "../../globals/style";
import { Card, CardSubTitle, CardTitle } from "../../components/Card/";
import axios from "axios";
import { Notify } from "notiflix";
import { IoCheckboxOutline, IoWarningOutline } from "react-icons/io5";
import { FormButton } from "../../components/Form";
import { Link } from "react-router-dom";

const DIndex = () => {
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState(0);
  const [user, setUser] = useState({});

  function maskNumber(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4,5})(\d{4})/, "$1-$2");
  }

  useEffect(() => {
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .get(process.env.REACT_APP_URL + "/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let me = response.data;
        if (!me.active) {
          Notify.failure(
            'Você não está com uma assinatura ativa, vá até a aba <b>"Assinatura"</b> e renove seu plano.'
          );
        }
        setPhone(me.phone ? maskNumber(me.phone) : "");
        setUser(me);
      });
  }, []);

  return (
    <>
      <Card>
        <CardTitle>
          Seja bem-vindo ao GestorZap, <Highlight>{user.first_name}</Highlight>!
        </CardTitle>
      </Card>

      <Card
        style={{
          display: user.active ? "none" : "flex",
          flexDirection: "column",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "15px",
          gap: 1,
        }}
      >
        <IoWarningOutline size={64} color="#1d0cff" />
        <CardTitle style={{ textAlign: "center" }}>
          Sua conta está <Danger>INATIVA</Danger>!
        </CardTitle>
        <CardSubTitle style={{ marginTop: "-10px" }}>
          Para ativar, renove seu plano na guia <b>Assinatura</b>.
        </CardSubTitle>
      </Card>

      <Card
        style={{
          display: !user.active ? "none" : "flex",
          flexDirection: "column",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "15px",
          gap: 1,
        }}
      >
        <IoCheckboxOutline size={64} color="#1d0cff" />
        <CardTitle style={{ textAlign: "center" }}>
          Sua conta está <Highlight>ATIVA</Highlight>!
        </CardTitle>
        <CardSubTitle
          style={{
            display: user.phone ? "none" : "block",
            marginTop: "-10px",
          }}
        >
          Mas você ainda{" "}
          <b>
            <Danger>não está</Danger>
          </b>{" "}
          com seu número de WhatsApp configurado. Configure na guia{" "}
          <b>Configuração</b>.
        </CardSubTitle>

        <CardSubTitle
          style={{
            display: !user.phone ? "none" : "block",
            marginTop: "-5px",
          }}
        >
          E você{" "}
          <b>
            <Highlight>já está</Highlight>
          </b>{" "}
          com seu número de WhatsApp configurado.{" "}
          <b>Clique abaixo para iniciar uma conversa</b>.
        </CardSubTitle>

        <Link
          to={"https://gestorzap.com/bot"}
          style={{
            display: user.phone ? "flex" : "none",
            width: "100%",
            justifyContent: "center",
            textDecoration: "none",
          }}
        >
          <FormButton
            type="button"
            style={{
              width: "100%",
              color: "white",
              fontWeight: "500",
              marginTop: "0px",
              marginBottom: "10px",
            }}
          >
            Clique aqui para iniciar uma conversa
          </FormButton>
        </Link>
      </Card>
    </>
  );
};

export default DIndex;
