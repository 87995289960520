import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.02);
  color: white;
  overflow: hidden;
  padding: 10px 20px;
  border-radius: 10px;
  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const CardTitle = styled.h1`
  color: white;
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const CardSubTitle = styled.p`
  color: rgba(255, 255, 255, 0.5);
  @media (max-width: 425px) {
    text-align: center;
  }
`;
