import { useEffect, useState } from "react";
import { Danger, Highlight } from "../../globals/style";
import { Card, CardSubTitle, CardTitle } from "../../components/Card/";
import axios from "axios";
import { Notify } from "notiflix";
import { IoCheckboxOutline, IoWarningOutline } from "react-icons/io5";
import { FormButton, FormContainer, FormInput } from "../../components/Form";
import { Link } from "react-router-dom";
import { Disclaimer, DisclaimerLink } from "../Login/styles";

const DSettings = () => {
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState(0);
  const [user, setUser] = useState({});

  function maskNumber(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4,5})(\d{4})/, "$1-$2");
  }

  useEffect(() => {
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .get(process.env.REACT_APP_URL + "/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let me = response.data;
        if (!me.active) {
          Notify.failure(
            'Você não está com uma assinatura ativa, vá até a aba <b>"Assinatura"</b> e renove seu plano.'
          );
        }
        setPhone(me.phone ? maskNumber(me.phone) : "");
        setUser(me);
      });
  }, []);

  function onPhoneChange(e) {
    setPhone(maskNumber(e.target.value));
  }

  function onGenderChange(e) {
    setGender(e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    let newPhone = phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");

    if (newPhone.length < 11) {
      return Notify.failure(
        "Seu número não pode ter menos de 11 digitos. Não esqueça o 9 inicial."
      );
    }

    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .put(
        process.env.REACT_APP_URL + "/phone/",
        {
          phone: newPhone,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setUser(res.data);
        Notify.success("Número de telefone vinculado com sucesso.");
      })
      .catch((res) => {
        Notify.failure("Você já tem o número de telefone vinculado.");
      });
  }

  return (
    <>
      <Card>
        <CardTitle>
          Ambiente de Configuração do <Highlight>GestorZap</Highlight>
        </CardTitle>
      </Card>

      <Card
        style={{
          display: user.active ? "none" : "flex",
          flexDirection: "column",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "15px",
          gap: 1,
        }}
      >
        <IoWarningOutline size={64} color="#f94449" />
        <CardTitle style={{ textAlign: "center" }}>
          Sua conta está <Danger>INATIVA</Danger>!
        </CardTitle>
        <CardSubTitle style={{ marginTop: "-10px" }}>
          Para ativar, renove seu plano na guia <b>Assinatura</b>.
        </CardSubTitle>
      </Card>

      <Card
        style={{
          display: !user.active ? "none" : !user.phone ? "none" : "flex",
          flexDirection: "column",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "15px",
          gap: 1,
        }}
      >
        <IoCheckboxOutline size={64} color="#1d0cff" />
        <CardTitle style={{ textAlign: "center" }}>
          Seu WhatsApp está <Highlight>CONFIGURADO</Highlight>!
        </CardTitle>
      </Card>

      <Card
        style={{
          display: !user.active ? "none" : user.phone ? "none" : "flex",
          flexDirection: "column",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "15px",
          gap: 1,
        }}
      >
        <IoCheckboxOutline size={64} color="#f94449" />
        <CardTitle style={{ textAlign: "center" }}>
          Seu WhatsApp ainda <Danger>NÃO ESTÀ </Danger> configurado!
        </CardTitle>
      </Card>

      <Card
        style={{
          display: user.active ? "flex" : "none",
          marginTop: "20px",
          flexDirection: "column",
        }}
      >
        <CardTitle>Configure seu WhatsApp aqui!</CardTitle>
        <FormContainer
          onSubmit={onSubmit}
          style={{
            width: "100%",
            padding: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormInput
            value={phone}
            onChange={onPhoneChange}
            placeholder="(00) 00000-0000"
            maxLength={15}
            style={{
              height: "50px",
              borderRadius: "0px",
            }}
            disabled={user.phone ? true : false}
            required
          />
          {user.phone ? (
            <></>
          ) : (
            <>
              <FormButton style={{ width: "30%", marginTop: "10px" }}>
                Salvar
              </FormButton>
            </>
          )}
        </FormContainer>
        {user.phone ? (
          <>
            <Disclaimer>
              Por algum motivo, deseja alterar seu WhatsApp?{" "}
              <DisclaimerLink to={"https://gestorzap.com/suporte"}>
                Clique aqui e fale com nosso suporte
              </DisclaimerLink>
            </Disclaimer>
          </>
        ) : (
          <>
            <Disclaimer>
              Precisa falar com nosso suporte?{" "}
              <DisclaimerLink to={"https://gestorzap.com/suporte"}>
                Clique aqui
              </DisclaimerLink>
            </Disclaimer>
          </>
        )}
      </Card>
    </>
  );
};

export default DSettings;
