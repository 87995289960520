import styled from "styled-components";

export const Table = styled.table`
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
export const TableHeader = styled.td`
  font-weight: 600;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;
export const TableRow = styled.tr`
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
`;

export const ActiveBadge = styled.p`
  background-color: ${(props) => (props.active ? "lightgreen" : "red")};
  color: ${(props) => (props.active ? "black" : "white")};
`;

export const TableBody = styled.tbody``;
export const TableHead = styled.thead``;

export const TableCell = styled.td`
  padding: 15px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;
