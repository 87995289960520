import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import DashboardIndex from "./pages/DashboardIndex";
import ProtectedRoute from "./utils/ProtectedRoute";
import AdminIndex from "./pages/Admin/AdminIndex";
import AdminCreateUser from "./pages/Admin/AdminCreateUser";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { create } from "@mui/material/styles/createTransitions";
import BaseLayout from "./components/BaseLayout";
import DIndex from "./pages/DIndex";
import DSettings from "./pages/DSettings";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5200FF",
    },
  },
  typography: {
    fontFamily: "Montserrat-Semibold",
  },
});

const AppRouter = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<BaseLayout />}>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DIndex />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/settings"
              element={
                <ProtectedRoute>
                  <DSettings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/create"
            element={
              <ProtectedRoute>
                <AdminCreateUser />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AppRouter;
