import { LogoImage } from "./styles";
import logoMatchyia from "../../assets/logo.png";

const Logo = (props) => {
  return (
    <>
      <LogoImage src={logoMatchyia} width={props.width} />
    </>
  );
};

export default Logo;
