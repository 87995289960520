import styled from "styled-components";

export const Logo = styled.img`
  width: 32px;
  padding: 10px;
`;

export const Highlight = styled.span`
  color: #1d0cff;
  font-weight: 700;
`;

export const Danger = styled.span`
  color: #f94449;
  font-weight: 700;
`;
