import styled from "styled-components";

export const BaseContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 100px;
  @media (max-width: 425px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
