import styled from "styled-components";

export const DashboardContainer = styled.div`
  min-height: 60vh;
  padding-bottom: 30px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;
