import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import { createTheme, ThemeProvider } from "@mui/material";
import { IoHome, IoHomeOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { MdOutlinePayments } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";

import Logo from "../Logo";
import {
  CreditCardOutlined,
  HomeOutlined,
  Settings,
  SettingsOutlined,
} from "@material-ui/icons";
import {
  HomeMaxOutlined,
  IosShareOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.00)",
    display: "flex",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.0)",
  },
  drawer: {
    flexShrink: 0,
    color: "white",
    width: drawerWidth,
  },
  drawerPaper: {
    backgroundColor: "#06072e",
    border: "1px solid rgba(255,255,255,0.05)",
    color: "white",
    width: drawerWidth,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat, Arial",
    },
  });
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .get(process.env.REACT_APP_URL + "/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let me = response.data;
        setUser(me);
        setName(me.first_name);
      });
  }, []);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar
          style={{
            backgroundColor: "transparent",
            border: "none",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Logo width={150} />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem component={Link} to={"/dashboard"} button key={"inicio"}>
            <ListItemIcon>
              <IoHomeOutline size={25} color="#1d0cff" />
            </ListItemIcon>
            <ListItemText primary={"Início"} />
          </ListItem>
          <ListItem
            component={Link}
            to={"/dashboard/settings"}
            button
            key={"config"}
          >
            <ListItemIcon>
              <LuSettings2 size={25} color="#1d0cff" />
            </ListItemIcon>
            <ListItemText primary={"Configuração"} />
          </ListItem>
          <ListItem
            component={Link}
            to={"https://purchase.hotmart.com"}
            button
            target="_blank"
            key={"config"}
          >
            <ListItemIcon>
              <MdOutlinePayments size={25} color="#1d0cff" />
            </ListItemIcon>
            <ListItemText primary={"Assinatura"} />
          </ListItem>
        </List>
        <List
          style={{ marginTop: `auto`, borderTop: "1px solid rgba(0,0,0,0.1)" }}
        >
          <ListItem
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "35px",
                width: "35px",
                color: "#1d0cff",
                backgroundColor: "rgba(29, 12, 255, 0.05)",
                border: "1px solid #1d0cff",
                fontWeight: "bold",
                borderRadius: "30px",
                marginRight: "10px",
              }}
            >
              {name.substring(0, 1)}
            </Box>

            <ListItemText>{name.substring(0, 10)}...</ListItemText>
            <LogoutOutlined />
          </ListItem>
        </List>
      </Drawer>
    </ThemeProvider>
  );
}
