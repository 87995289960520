import { useCallback, useEffect, useState } from "react";
import {} from "../../globals/style";
import axios from "axios";
import { APP_URL } from "../../config";

import {
  FormContainer,
  FormInput,
  FormTitle,
  FormButton,
} from "../../components/Form";

import {
  LoginContainer,
  MainContainer,
  PhotoContainer,
  Disclaimer,
  DisclaimerLink,
} from "./styles";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router";
import { Loading, Notify } from "notiflix";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function onPassChange(e) {
    setPassword(e.target.value);
  }

  function FormSubmit(e) {
    e.preventDefault();
    Loading.circle();
    axios
      .post(
        APP_URL + "/auth/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        Loading.remove();
        const date = new Date();
        date.setTime(date.getTime() + (response.data.expires_in - 5) * 1000);

        document.cookie = `token=${
          response.data.access_token
        };expires=${date.toUTCString()};secure;SameSite=None;`;

        Notify.success("Login realizado, redirecionando a Dashboard.");
        navigate("/dashboard");
      })
      .catch(function (error) {
        Loading.remove();
        Notify.failure("E-mail ou senha incorretos.");
      });
  }

  return (
    <MainContainer>
      <LoginContainer>
        <FormContainer onSubmit={FormSubmit}>
          <Logo width="200" />
          <FormTitle>Logue-se na sua conta</FormTitle>
          <FormInput
            name="email"
            type="email"
            placeholder="Endereço de e-mail"
            value={email ? email : ""}
            onChange={onEmailChange}
            required
          />

          <FormInput
            name="password"
            type="password"
            placeholder="Senha"
            value={password ? password : ""}
            onChange={onPassChange}
            required
          />

          <FormButton>Entrar</FormButton>
          <Disclaimer>
            Ainda não tem uma conta?
            <DisclaimerLink to={"https://gestorzap.com"}>
              {" "}
              Clique aqui e adquira.
            </DisclaimerLink>
          </Disclaimer>
        </FormContainer>
      </LoginContainer>
      <PhotoContainer></PhotoContainer>
    </MainContainer>
  );
};

export default Login;
